





























































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import NestedMultiSelect from '../../../Offer/components/NestedMultiSelect/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import CalendarIcon from '@/app/ui/assets/ics_o_calendar.vue'
import CheckBox from '../../../Payroll/components/CheckBox/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import { ValidationRule, requiredIf } from 'vuelidate/lib/validators'
import {
  PickupTimeDetail,
  TimeSlot,
} from '@/domain/entities/PickupTimeManagement'
import {
  ITimeSlotForm,
  ISlot,
} from '@/data/infrastructures/misc/interfaces/pickupTimeManagement'
import { timeSlotFormSkeleton } from '@/app/infrastructures/misc/Constants/pickupTimeManagement'
import ChevronBullet from '@/app/ui/assets/ics_f_chevron_up_bullet.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import WarningCircleIcon from '@/app/ui/assets/ics_f_warning_circle_red.vue'
import TimePicker from '@/app/ui/components/TimePicker/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import PlusIcon from '@/app/ui/assets/ics_f_plus_circle.vue'
import TrashIcon from '@/app/ui/assets/ics_f_trash.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import controller from '@/app/ui/controllers/PickupTimeManagementController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ExpandIcon from '@/app/ui/assets/chevron_down.vue'
import WarningIcon from '@/app/ui/assets/icon_warning_triangles.vue'
import { UpdateDynamicPickupRequest } from '@/data/payload/api/PickupTimeManagementRequest'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'

interface IForm {
  isContinually: boolean
  periodeStart: Date | null
  periodeEnd: Date | null
  timeSlot: ITimeSlotForm[]
}

interface IValidation {
  form: {
    periodeStart: {
      required: ValidationRule
    }
    periodeEnd: {
      required: ValidationRule
      isMoreThanPeriodeStart: (value: Date | null) => boolean
    }
    timeSlot: {
      $each: {
        slots: {
          $each: {
            startTime: {
              required: ValidationRule
              moreThanPreviousStartTime: (val: Date, vm: ISlot) => boolean
            }
            endTime: {
              required: ValidationRule
              lessThanStartTime: (val: Date, vm: ISlot) => boolean
            }
            pickupTimeLimit: {
              minDigits: (val: number, vm: ISlot) => boolean | ValidationRule
              maxDigits: (val: number, vm: ISlot) => boolean | ValidationRule
              required: ValidationRule
            }
          }
        }
      }
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    NestedMultiSelect,
    DateTimePickerV2,
    CalendarIcon,
    CheckBox,
    DataTableV2,
    ChevronBullet,
    WarningCircleIcon,
    TimePicker,
    TextInput,
    Button,
    PlusIcon,
    TrashIcon,
    ModalConfirm,
    LoadingOverlay,
    ExpandIcon,
    WarningIcon,
    ModalSuccess,
  },
})
export default class PickupTimeManagementCreate extends Vue {
  controller = controller

  form: IForm = {
    isContinually: false,
    periodeStart: null,
    periodeEnd: null,
    timeSlot: [],
  }

  detailData: PickupTimeDetail = {}

  days = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']
  hourOptions = Array.from({ length: 25 }, (_n, index) => index)

  modalSubmitConfirmVisible = false
  modalSuccessVisible = false

  created(): void {
    this.fetchDetail()
    this.setInitialTimeSlotForm()
  }

  @Validations()
  validations(): IValidation {
    return {
      form: {
        periodeStart: {
          required: requiredIf(() => {
            return !this.isDefaultTimeSlot
          }),
        },
        periodeEnd: {
          required: requiredIf(() => {
            if (this.isDefaultTimeSlot) return false
            return !this.form.isContinually
          }),
          isMoreThanPeriodeStart: this.isMoreThanPeriodeStart,
        },
        timeSlot: {
          $each: {
            slots: {
              $each: {
                startTime: {
                  required: requiredIf(val => {
                    if (!val.id) return Boolean(Object.keys(val).length)
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === val.id
                      })
                    })
                    if (val.endTime || val.pickupTimeLimit) return true
                    if (!val.endTime || !val.pickupTimeLimit)
                      return parent?.isActive
                  }),
                  moreThanPreviousStartTime: (val, vm) => {
                    const prevRow = <ITimeSlotForm>(
                      this.form.timeSlot.find(slot =>
                        slot.slots.find(item => item.id === vm.id)
                      )
                    )
                    const currentIdx = <number>(
                      prevRow?.slots.findIndex(slot => slot.id === vm.id)
                    )
                    if (currentIdx > 0) {
                      if (
                        val &&
                        prevRow &&
                        prevRow.slots[currentIdx - 1].startTime
                      ) {
                        return (
                          val.getTime() >=
                          <number>(
                            prevRow?.slots[currentIdx - 1]?.startTime?.getTime()
                          )
                        )
                      }
                    }
                    return true
                  },
                },
                endTime: {
                  required: requiredIf(val => {
                    if (!val.id) return Boolean(Object.keys(val).length)
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === val.id
                      })
                    })
                    if (val.startTime || val.pickupTimeLimit) return true
                    if (!val.startTime || !val.pickupTimeLimit)
                      return parent?.isActive
                  }),
                  lessThanStartTime: (val, vm) => {
                    if (val && vm.startTime) {
                      return val.getTime() > vm.startTime.getTime()
                    }
                    return true
                  },
                },
                pickupTimeLimit: {
                  minDigits: (val, vm) => {
                    if (!vm.id) return true
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === vm.id
                      })
                    })
                    if (vm.startTime || vm.endTime)
                      return !val ? true : val >= 10
                    if (!vm.startTime || !vm.endTime)
                      return <boolean>parent?.isActive || !val
                        ? true
                        : val >= 10
                    return true
                  },
                  maxDigits: (val, vm) => {
                    if (!vm.id) return true
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === vm.id
                      })
                    })
                    if (vm.startTime || vm.endTime)
                      return !val ? true : val <= 999999
                    if (!vm.startTime || !vm.endTime)
                      return <boolean>parent?.isActive || !val
                        ? true
                        : val <= 999999
                    return true
                  },
                  required: requiredIf(val => {
                    if (!val.id) return Boolean(Object.keys(val).length)
                    const parent = this.form.timeSlot.find(slot => {
                      return slot.slots.some(slot => {
                        return slot.id === val.id
                      })
                    })
                    if (val.startTime || val.endTime) return true
                    if (!val.startTime || !val.endTime) return parent?.isActive
                  }),
                },
              },
            },
          },
        },
      },
    }
  }

  get periodeStartPlaceholder(): string {
    if (this.form.periodeStart) {
      return Utils.formatDateWithIDLocale(
        this.form.periodeStart.toISOString(),
        'DD/MM/YYYY'
      )
    }
    return 'DD/MM/YYYY'
  }

  get periodeEndPlaceholder(): string {
    if (this.form.periodeEnd) {
      return Utils.formatDateWithIDLocale(
        this.form.periodeEnd.toISOString(),
        'DD/MM/YYYY'
      )
    }
    return 'DD/MM/YYYY'
  }

  get periodeStartErrMsg(): string {
    return 'Periode mulai harus diisi'
  }

  get periodeEndErrMsg(): string {
    if (this.isPeriodeSame()) {
      return 'Periode berakhir tidak boleh sama dari periode mulai. Cek lagi'
    }
    if (!this.isMoreThanPeriodeStart(this.form.periodeEnd)) {
      return 'Periode berakhir harus lebih besar dari periode mulai. Cek lagi'
    }
    return 'Periode berakhir harus diisi'
  }

  get isDefaultTimeSlot(): boolean {
    return !controller.pickupTimeDetailData.city
  }

  private fetchDetail(): void {
    const id = this.$route.params.id
    this.controller.getDetailPickupTime(parseInt(id))
  }

  public onToggleSwitch(index: number): void {
    this.form.timeSlot[index].isActive = !this.form.timeSlot[index].isActive
    this.form.timeSlot[index].state = this.form.timeSlot[index].isActive
    this.form.timeSlot[index].isDisabled = false
  }

  public onDisableData(index: number, value: boolean): void {
    this.form.timeSlot[index].isDisabled = value
  }

  public onCollapse(index: number): void {
    this.form.timeSlot = this.form.timeSlot.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          state: !item.state,
        }
      }

      return item
    })
  }

  public addTimeSlot(index: number): void {
    this.form.timeSlot[index].slots.splice(
      this.form.timeSlot[index].slots.length - 1,
      0,
      {
        id: new Date().getTime(),
        endTime: undefined,
        pickupTimeLimit: undefined,
        startTime: undefined,
        timeZone: '',
      }
    )
  }

  public deleteTimeSlot(index: number, i: number): void {
    this.form.timeSlot[index].slots.splice(i, 1)
  }

  public onCheckContinually(): void {
    this.form.isContinually = !this.form.isContinually

    if (this.form.isContinually) {
      this.form.periodeEnd = null
    }
  }

  private isMoreThanPeriodeStart(value: Date | null): boolean {
    if (value && this.form.periodeStart) {
      return value.getTime() > this.form.periodeStart.getTime()
    }

    return true
  }

  private isPeriodeSame(): boolean {
    if (this.form.periodeEnd && this.form.periodeStart) {
      return (
        Utils.formatDate(this.form.periodeStart.toISOString(), 'DD/MM/YYYY') ===
        Utils.formatDate(this.form.periodeEnd.toISOString(), 'DD/MM/YYYY')
      )
    }

    return false
  }

  private setInitialTimeSlotForm(): void {
    this.form.timeSlot = <ITimeSlotForm[]>timeSlotFormSkeleton.map(item => {
      const data = item[Object.keys(item)[0] as keyof TimeSlot]
      return {
        isActive: true,
        slots: data?.slot?.map(item => item),
        state: true,
      }
    })
  }

  public onTimeInput(
    val: {
      hour: string | number
      minute: string | number
    },
    index: number,
    i: number,
    type: 'startTime' | 'endTime'
  ): void {
    let newValue = new Date()
    if (val.hour === '24') {
      newValue.setHours(23, 59, 0, 0)
    } else if (parseInt(<string>val.minute) > 30) {
      newValue.setHours(parseInt(<string>val.hour), 30, 0, 0)
    } else {
      newValue.setHours(
        parseInt(<string>val.hour),
        parseInt(<string>val.minute),
        0,
        0
      )
    }
    this.form.timeSlot[index].slots[i][type] = newValue
  }

  public onInputPickupTimeLimit(val: string, index: number, i: number): void {
    if (parseInt(val) === 0) {
      this.form.timeSlot[index].slots[i].pickupTimeLimit = undefined
    }
  }

  public onCancel(): void {
    this.$router.push({ name: 'PickupTimeManagementTabPickupTime' })
  }

  public onConfirmSubmit(): void {
    this.modalSubmitConfirmVisible = true
  }

  public onSubmit(): void {
    const payload = new UpdateDynamicPickupRequest({
      isDefault: this.isDefaultTimeSlot,
      dynamicPickupId: <number>this.detailData.dynamicPickupId,
      routeId: <number>this.detailData.routeId,
      periodeStart: <Date>this.form.periodeStart,
      periodeEnd: <Date>this.form.periodeEnd,
      timeSlot: this.form.timeSlot,
    })

    this.controller.updateDynamicPickupTime(payload)

    this.modalSubmitConfirmVisible = false
  }

  @Watch('controller.pickupTimeDetailData')
  onPickupTimeDetailDataChange(data: PickupTimeDetail): void {
    this.detailData = {
      ...data,
      createdAt: data.createdAt
        ? Utils.formatDateWithIDLocale(
            <string>data.createdAt,
            'DD MMMM YYYY, HH:mm'
          )
        : '-',
      updatedAt: data.updatedAt
        ? Utils.formatDateWithIDLocale(
            <string>data.updatedAt,
            'DD MMMM YYYY, HH:mm'
          )
        : '-',
      periodeStart: Utils.formatDateWithIDLocale(
        <string>data.periodeStart,
        'DD/MM/YYYY'
      ),
      periodeEnd: Utils.formatDateWithIDLocale(
        <string>data.periodeEnd,
        'DD/MM/YYYY'
      ),
    }

    if (data.periodeStart) {
      this.form.periodeStart = new Date(
        `${data.periodeStart} 00:00${Utils.forceTimeZoneAsiaJakarta()}`
      )
    }

    if (data.periodeEnd) {
      this.form.periodeEnd = new Date(
        `${data.periodeEnd} 00:00${Utils.forceTimeZoneAsiaJakarta()}`
      )
    } else {
      this.form.isContinually = true
    }

    this.form.timeSlot = <ITimeSlotForm[]>data.timeSlotPickup?.map(
      (item, index) => {
        const data = item[Object.keys(item)[index] as keyof TimeSlot]
        if (data?.slot) {
          data?.slot?.push({})
        }

        return {
          isDisabled: data?.isDisabled,
          isActive: data?.status ? data?.status === 'active' : false,
          slots: data?.slot
            ? data?.slot?.map((item, i) => {
                let newItem = {
                  ...item,
                  id: item.timeZone
                    ? new Date().getTime() + index + i + Math.random()
                    : undefined,
                  startTime: item.startTime
                    ? new Date(
                        new Date(
                          `${Utils.formatDateWithIDLocale(
                            new Date().toISOString(),
                            'YYYY-MM-DD'
                          )} ${
                            item.startTime
                          }${Utils.forceTimeZoneAsiaJakarta()}`
                        ).setDate(new Date().getDate())
                      )
                    : undefined,
                  endTime: item.endTime
                    ? new Date(
                        new Date(
                          `${Utils.formatDateWithIDLocale(
                            new Date().toISOString(),
                            'YYYY-MM-DD'
                          )} ${item.endTime}${Utils.forceTimeZoneAsiaJakarta()}`
                        ).setDate(new Date().getDate())
                      )
                    : undefined,
                }

                if (!newItem.timeZone) {
                  delete newItem.id
                  delete newItem.startTime
                  delete newItem.endTime
                }

                return newItem
              })
            : [
                {
                  id: new Date().getTime() + index + Math.random(),
                  endTime: null,
                  startTime: null,
                  pickupTimeLimit: null,
                  timeZone: '',
                },
                {},
              ],
          state: true,
        }
      }
    )

    if (this.$v.form.timeSlot) {
      this.$v.form.timeSlot.$touch()
    }
  }

  @Watch('controller.isUpdateDynamicPickupSuccess')
  onIsUpdateDynamicPickupSuccessChanged(data: boolean): void {
    if (data) {
      this.modalSuccessVisible = true
      this.controller.setIsUpdateDynamicPickupSuccess(false)
    }
  }
}
