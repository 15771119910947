import { TimeSlot } from '@/domain/entities/PickupTimeManagement'

export enum PickupTimeSortFields {
  CITY = 'city',
  ROUTE = 'route',
  PERIODE_START = 'periode_start',
  PERIODE_END = 'periode_end',
}

export enum PickupTimeSortType {
  CITY_ASC = 'city-asc',
  CITY_DESC = 'city-desc',
  ROUTE_ASC = 'route-asc',
  ROUTE_DESC = 'route-desc',
  PERIODE_START_ASC = 'periode_start-asc',
  PERIODE_START_DESC = 'periode_start-desc',
  PERIODE_END_ASC = 'periode_end-asc',
  PERIODE_END_DESC = 'periode_end-desc',
}

export enum PickupTimeStatus {
  ACTIVE = 'Aktif',
  WAITING = 'Menunggu',
  EXPIRED = 'Kadaluarsa',
}

export const timeSlotFormSkeleton = <TimeSlot[]>[
  {
    monday: {
      status: 'active',
      slot: [
        { 
          id: new Date().getTime() + Math.random(),
          startTime: null,
          endTime: null,
          pickupTimeLimit: null,
          timeZone: '',
        },
        {},
      ],
    },
  },
  {
    tuesday: {
      status: 'active',
      slot: [
        { 
          id: new Date().getTime() + Math.random(),
          startTime: null,
          endTime: null,
          pickupTimeLimit: null,
          timeZone: '',
        },
        {},
      ],
    },
  },
  {
    wednesday: {
      status: 'active',
      slot: [
        {
          id: new Date().getTime() + Math.random(),
          startTime: null,
          endTime: null,
          pickupTimeLimit: null,
          timeZone: '',
        },
        {},
      ],
    },
  },
  {
    thursday: {
      status: 'active',
      slot: [
        {
          id: new Date().getTime() + Math.random(),
          startTime: null,
          endTime: null,
          pickupTimeLimit: null,
          timeZone: '',
        },
        {},
      ],
    },
  },
  {
    friday: {
      status: 'active',
      slot: [
        {
          id: new Date().getTime() + Math.random(),
          startTime: null,
          endTime: null,
          pickupTimeLimit: null,
          timeZone: '',
        },
        {},
      ],
    },
  },
  {
    saturday: {
      status: 'active',
      slot: [
        {
          id: new Date().getTime() + Math.random(),
          startTime: null,
          endTime: null,
          pickupTimeLimit: null,
          timeZone: '',
        },
        {},
      ],
    },
  },
  {
    sunday: {
      status: 'active',
      slot: [
        {
          id: new Date().getTime() + Math.random(),
          startTime: null,
          endTime: null,
          pickupTimeLimit: null,
          timeZone: '',
        },
        {},
      ],
    },
  },
]
